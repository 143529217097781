import React from "react";

import '../App.css';

export default class Footer extends React.Component {


	render() {
		return (


			<footer>

				<div className="footerContainer">

					<div className="footerAbout">
						<h2>Bryggerilagret</h2>
						<p>Om Bryggerilagret</p>
						<p>Integritetspolicy</p>
						<p>Kontakta oss</p>
					</div>

					<div className="footerInfo">

						<h2>Företagsuppgifter</h2>
						<p>Bryggerilagret/Heikki Räätäri</p>
						<p>SE610601239301</p>
						<p>Bg 5498-8290</p>
						<p>info@bryggerilagret.se</p>
					</div>

				</div>

				<p className="allRights">&copy; All rights reserved</p>
			</footer>



		);
	}
}