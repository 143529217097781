import React from "react";

import '../../App.css';

export default class Bestallningar extends React.Component {


	render() {
		return (

			<section className="bestallningar" id="bestallningar">
				bestallningar
			</section>



		);
	}
}