import React from "react";
import axios from 'axios';
import Cookies from 'js-cookie';

import '../App.css';

export default class ProduktNav extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			shoppingCart: [],
			userInfo: [],
			adress: "",
			postnummer: "",
			postort: "",
			name: Cookies.get("completeName")
		};


		this.handle_order_info = this.handle_order_info.bind(this);
		//this.deleteFromCart = this.deleteFromCart.bind(this);

	}

	componentDidMount() {
		if (this.state.name) {
			fetch('/api/user/info')
				.then(res => res.json())
				.then(userInfo => {
					this.setState({ userInfo });
				});
		}
	}

	componentDidUpdate() {
		//console.log(this.props.updateCartIcon.length);
		if (this.state.shoppingCart !== this.props.shoppingCart) {
			this.setState({
				shoppingCart: this.props.shoppingCart
			});
		}

	}

	closeNav = () => {
		document.getElementById("produktNav").style.width = "0";
		document.getElementById("main").style.marginLeft = "0";
		document.getElementById("headerMain").style.marginLeft = "0px";
	}



	changeToInfo = () => {
		document.getElementById("pageContainerCart").style.marginLeft = "-650px";
	}

	backToCart = () => {
		document.getElementById("pageContainerCart").style.marginLeft = "0px";
	}

	sendOrder = () => {



		if (this.state.adress === "" || this.state.postnummer === "" || this.state.postort === "") {
			alert("Var snäll och fyll i alla fält");
		} else {
			const formData = new FormData();
			formData.append('user_id', this.state.userInfo[0].user_id);

			//formData.append('tot_price', this.state.textarea);

			formData.append('product_list', JSON.stringify(this.state.shoppingCart));

			formData.append('adress', this.state.adress);
			formData.append('postnummer', this.state.postnummer);
			formData.append('postort', this.state.postort);

			const config = {
				headers: {
					'content-type': 'multipart/form-data'
				}
			};

			axios.post("/api/create/order", formData, config)
				.then((response) => {
					this.setState({
						shoppingCart: []
					});
					this.props.updateCartIcon();
					document.getElementById("pageContainerCart").style.marginLeft = "-1300px";
				}).catch((error) => {
					console.log("Something went wrong!");
				});
		}



	}

	handle_order_info(event) {
		console.log(event.target.name + " " + event.target.value);

		this.setState({
			[event.target.name]: event.target.value
		});

	}

	closeProductNav = () => {
		document.getElementById("produktNav").style.width = "0";
		document.getElementById("pageContainerCart").style.marginLeft = "0px";
		document.getElementById("main").style.marginLeft = "0px";
		document.getElementById("headerMain").style.marginLeft = "0px";
	}


	render() {
		return (

			<div id="produktNav" className="produktNav">
				<div className="pageContainerCart" id="pageContainerCart">
					{this.state.shoppingCart.length > 0 ? (
						<div className="firstPageInCart">
							<p className="closebtnProduktNav" onClick={this.closeNav}>×</p>
							<h1>Kundvagn</h1>

							<div className="headerCartContainer">
								<p>Produkt</p>
								<p>Behållare</p>
								<p>Antal</p>
								<p>Pris</p>
							</div>

							<div className="cartContainer">
								{
									this.state.shoppingCart.map(shoppingCart =>
										<div key={shoppingCart.id + shoppingCart.type} className="shoppingCartProduct">
											{/*
											<div className="containerImgCart">
												<img src={require("../uploads/produkter/" + shoppingCart.logo + "").default} alt={shoppingCart.logo + " logo"}></img>
											</div>
											*/}
											<p>{shoppingCart.namn}</p>
											<p>{shoppingCart.type}</p>
											<p>{shoppingCart.antal}</p>
											<p>{(shoppingCart.price * shoppingCart.antal).toFixed(2)}</p>
											<p onClick={() => this.props.deleteFromCart(shoppingCart.id, shoppingCart.type)}>X</p>
										</div>
									)
								}
							</div>

							<button onClick={this.changeToInfo}>Fortsätt</button>

						</div>
					) : (
						<div>
							<p className="closebtnProduktNav" onClick={this.closeNav}>×</p>
							<p className="cartIsEmpty">Kundvagnen är tom</p>
						</div>
					)
					}

					<div className="secondPageInCart">
						<p className="closebtnProduktNav" onClick={this.closeNav}>×</p>
						<p className="goBack" onClick={this.backToCart}>&larr;</p>
						<h1>Dina uppgifter</h1>

						<table>
							{this.state.userInfo.map(user => (

								<tbody key={user.user_id}>
									<tr>
										<td>Email: </td>
										<td>{user.email}</td>
									</tr>
									<tr>
										<td>Förnamn: </td>
										<td>{user.firstname}</td>
									</tr>
									<tr>
										<td>Efternamn: </td>
										<td>{user.surname}</td>
									</tr>
									<tr>
										<td>Bolagsnamn: </td>
										<td>{user.bolagsnamn}</td>
									</tr>
									<tr>
										<td>Organisations Nr: </td>
										<td>{user.Organisationsnummer}</td>
									</tr>
								</tbody>
							))}
						</table>


						<h1>Leveransuppgifter</h1>
						<table>
							<tbody>
								<tr>
									<td>Adress: </td>
									<td><input type="text" name="adress" onChange={this.handle_order_info}></input></td>
								</tr>
								<tr>
									<td>Postnummer: </td>
									<td><input type="text" name="postnummer" onChange={this.handle_order_info}></input></td>
								</tr>
								<tr>
									<td>Postort: </td>
									<td><input type="text" name="postort" onChange={this.handle_order_info}></input></td>
								</tr>
							</tbody>
						</table>

						<button onClick={this.sendOrder}>Bekräfta Beställning</button>

					</div>
					<div className="thirdPageInCart">
						<h1>Tack för din Beställning</h1>
						<p>Ett mail kommer att skickas när leveransen är påväg</p>
						<button onClick={this.closeProductNav}>Stäng</button>
					</div>
				</div>
			</div>

		);
	}
}