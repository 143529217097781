import React from "react";
import $, { event } from "jquery";

import '../../App.css';

export default class News extends React.Component {


	constructor(props) {
		super(props)
		this.state = {
			news: [],
			count: 1
		}

	}

	componentDidMount() {
		fetch('/api/news')
			.then(res => res.json())
			.then(news => {
				this.setState({ news });

				this.state.news.map(news => {
					return (
						//console.log(JSON.parse(news.all_img).length + " " + news.news_id),
						$("#movingSliderNews" + news.news_id).css('width', (JSON.parse(news.all_img).length * 100) + '%'),
						$("#movingSliderNews" + news.news_id + " div").css('width', (100 / JSON.parse(news.all_img).length) + '%')
					)
				}
				)
			});
	}

	nextImg = (id, antal) => {
		//alert(id)

		var currentNr = parseInt($("#imgCounter" + id).text());

		if (currentNr < antal) {
			$("#movingSliderNews" + id).animate({ "left": '-=100%' });
			currentNr = currentNr + 1;
			$("#imgCounter" + id).text(currentNr);
		}
	}

	prevImg = (id) => {
		//alert(id)

		var currentNr = parseInt($("#imgCounter" + id).text());

		if (currentNr > 1) {
			$("#movingSliderNews" + id).animate({ "left": '+=100%' });
			currentNr = currentNr - 1;
			$("#imgCounter" + id).text(currentNr);
		}
	}


	getDate = (id) => {

		var time_to_show = id;
		var t = new Date(time_to_show);
		var formatted = (t.toLocaleString()).slice(0, 10);
		return formatted;
	}



	render() {
		return (
			<section className="newsAbout" id="newsAbout">

				<p className="aboutRubrik">Nyheter</p>
				{
					this.state.news.map(news =>
						<div className="completeNews" id="completeNews" key={news.news_id}>
							{news.all_img === "[0]" ? (
								<>
									<p className="noimgNews">{news.rubrik}</p>
									<p className="noimgNews">{news.completeTxt}</p>
								</>
							) : (
								<>
									<div className="imageSliderNews">

										{
											JSON.parse(news.all_img).length > 1 ? (
												<div className="prev_img" onClick={() => this.prevImg(news.news_id)}>
													&#10092;
												</div>
											) : (
												<></>
											)
										}

										<div className="movingSliderNews" id={"movingSliderNews" + news.news_id}>
											{
												JSON.parse(news.all_img).map((images, i) => {
													return (
														<div className="imageSliderContainer" id={"imageSliderContainer" + news.news_id} key={images.img}>
														{/*
															<img src={require("../../uploads/newsimg/" + images.img + "").default} alt={i + " logo"}></img>
														*/}
															<img src={process.env.PUBLIC_URL + "/uploads/newsimg/" + images.img} alt={images.img + " logo"}></img>
														</div>

													)
												})
											}
										</div>

										{
											JSON.parse(news.all_img).length > 1 ? (
												<>
													<div className="next_img" onClick={() => this.nextImg(news.news_id, JSON.parse(news.all_img).length)}>
														&#10093;
													</div>
													<div className="imgCounter" id={"imgCounter" + news.news_id}>
														1
													</div>
												</>
											) : (
												<></>
											)
										}
									</div>
									<p>{news.rubrik}</p>
									<p>{news.completeTxt}</p>
								</>
							)
							}

							<p>{this.getDate(news.date)}</p>
						</div>
					)
				}
			</section>



		);
	}
}