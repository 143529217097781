import React from "react";

import '../../App.css';

export default class Bryggerilagret extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			about: "",
			settings: []
		}
	}

	componentDidMount() {

		fetch('/api/settings')
			.then(res => res.json())
			.then(settings => {
				this.setState({ settings });
			});

	}


	render() {
		return (

			<section className="bryggerilagret" id="bryggerilagret">
				<p className="aboutRubrik">Om Bryggerilagret</p>


				{this.state.settings.map(settings =>
					settings.about !== "null" ? (
						<p key={settings.setting_id}>{settings.about}</p>
					) : (
						<></>
					)
				)
				}
			</section>



		);
	}
}