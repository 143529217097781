import React from "react";
import $ from "jquery";
import axios from 'axios';

import Upload_img from "../../img/img_upload.png";
import Edit_icon from "../../img/edit_icon.png";
import Arrow from "../../img/arrow.png";


import '../../App.css';

export default class Produkter extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			file: Upload_img,
			allProdukter: [],
			allAktorer: [],
			allFormsProdukt: [],
			allFormsProduktFetched: [],
			namn: "",
			textarea: "",
			produktFolder: "produkter",
			edit: false,
			manufaktur: "choose_manufaktur"
		}

		this.priceOnProduct = "";
		this.img_upload = this.img_upload.bind(this);
		this.handleProduct = this.handleProduct.bind(this);
	}

	componentDidMount() {

		/*
		fetch('/api/produkter')
			.then(res => res.json())
			.then(allProdukter => {
				this.setState({ allProdukter });
				//console.log(allProdukter);
			});
*/

		this.fetchProduct();


		fetch('/api/aktorer')
			.then(res => res.json())
			.then(allAktorer => this.setState({ allAktorer }));

		this.getPrice = (id) => {
			let getOption = $("#produkt_id_" + id + " option:selected").text();

			const productId = id;
			let productPris = this.state.allProdukter.filter(item => item.produkt_id === productId);
			let getPriceForm = JSON.parse(productPris[0].formOfProdukt).filter(itemPrice => itemPrice.form === getOption);

			if (getOption === "Välj behållare" || getOption === "") {
				return "0";
			} else {
				return getPriceForm[0].pris;
			}
		}

	}

	fetchProduct = () => {
		axios.get("/api/produkter", { params: { from: "console" } })
			.then((response) => {
				this.setState({ allProdukter: response.data });
				console.log(response)
			}).catch((error) => {
				console.log("Something went wrong!");
			});
	}


	img_upload(event) {

		let file = event.target.files[0];
		let reader = new FileReader();

		if (event.target.files.length === 0) {
			return;
		}

		reader.onloadend = (e) => {
			this.setState({
				file: [reader.result],
				uppladdning: event.target.files[0]
			});

			$(".imgPreview").css({
				"width": "auto"
			});

			$(".close").css({
				"display": "block"
			});
		}

		reader.readAsDataURL(file);
	}

	clear_img = () => {
		this.setState({
			file: Upload_img
		});

		$(".imgPreview").css({
			"width": "50%"
		});

		$(".close").css({
			"display": "none"
		});
	}

	upload_produkt = (e) => {
		e.preventDefault();

		//console.log(this.state.uppladdning);

		const formData = new FormData();
		formData.append('file', this.state.uppladdning);
		formData.append('namn', this.state.namn);
		formData.append('textarea', this.state.textarea);
		formData.append('type', this.state.type);
		formData.append('alkHalt', this.state.alkHalt);
		formData.append('manufaktur', this.state.manufaktur);
		formData.append('formsOfProdukt', JSON.stringify(this.state.allFormsProdukt));

		//alert(formData.get("file") + " " + formData.get("namn"));


		const config = {
			headers: {
				'content-type': 'multipart/form-data'
			}
		};
		/*
			for (var pair of formData.entries()) {
				console.log(pair[0]+ ', ' + pair[1]); 
			}
			console.log(this.state.manufaktur + " " + JSON.stringify(this.state.allFormsProdukt).length)
		*/

		if (this.state.file === Upload_img || this.state.namn.length === 0 || this.state.textarea.length === 0 || this.state.type.length === 0
			|| this.state.alkHalt.length === 0 || this.state.manufaktur === "choose_manufaktur" || JSON.stringify(this.state.allFormsProdukt).length === 2) {
			alert("Alla fält är inte ifyllda!");
		} else {
			axios.post("/api/upload/produkt", formData, config)
				.then((response) => {

					this.setState({
						uppladdning: "",
						namn: "",
						textarea: "",
						type: "",
						alkHalt: "",
						manufaktur: "",
						allFormsProdukt: [],
						file: Upload_img
					});

					$('.addProdukt input').val('');
					$('.addProdukt textarea').val('');
					$('#shares').val('');
					$("#choose_man_select").val('choose_manufaktur');

					this.fetchProduct();

					console.log("The file is successfully uploaded");
					console.log(response);
				}).catch((error) => {
					console.log("Something went wrong!");
				});
		}
	}

	handle_namn = (event) => {
		this.setState({
			namn: event.target.value
		});
	}

	handle_type = (event) => {
		this.setState({
			type: event.target.value
		});
	}

	handle_textarea = (event) => {
		this.setState({
			textarea: event.target.value
		});
	}

	handle_alkHalt = (event) => {
		this.setState({
			alkHalt: event.target.value
		});
	}

	handle_manufaktur = (event) => {
		this.setState({
			manufaktur: event.target.value
		});
	}

	add_formOfProdukt = () => {

		let arrayWithForms = this.state.allFormsProdukt;
		const formOfProdukt = document.getElementById("formOfProdukt").value;
		const priceFormOfProdukt = document.getElementById("priceFormOfProdukt").value;
		var copy = false;

		for (var i = 0; i < arrayWithForms.length; i++) {
			if (this.state.allFormsProdukt[i].form === formOfProdukt) {
				copy = true
			}
		}

		if (copy) {
			alert("Behållare finns redan!");
		} else {
			arrayWithForms.push({
				form: formOfProdukt,
				pris: priceFormOfProdukt
			});

			this.setState({
				allFormsProdukt: arrayWithForms
			});

			document.getElementById("formOfProdukt").value = "";
			document.getElementById("priceFormOfProdukt").value = "";
		}
	}

	deleteProdukt(id, logo, pos) {
		var answer = window.confirm("Är du säker på att du vill ta bort produkten?");

		if (answer) {
			axios.get("/api/delete/produkt", { params: { produktID: id, logo: logo, pos: pos } })
				.then((response) => {
					this.fetchProduct();
				}).catch((error) => {
					console.log("Something went wrong!");
				});
		} else {
			// Do nothing!
			console.log('Ingen ändring gjord!');
		}
	}

	handleProduct(event) {
		//console.log(event.target.name + " " + event.target.value);
		//console.log(getPriceForm[0].pris);

		this.setState({
			productType: event.target.value
		});

	}

	editProdukt = (e, id, logo, namn, beskrivning, alkHalt, plats, type, formOfProdukt) => {
		e.preventDefault();

		$('#produktName').val(namn);
		$('#produktType').val(type);
		$('.addProdukt textarea').val(beskrivning);
		$('#produktAlkHalt').val(alkHalt);
		$('#shares').val('');
		$("#choose_man_select").val(plats);

		$("#produkt_id_" + id).val('chooseType');

		this.setState({
			edit: true,
			allFormsProdukt: JSON.parse(formOfProdukt),
			//file: require("../../uploads/produkter/" + logo).default,
			file: process.env.PUBLIC_URL + "/uploads/produkter/" + logo,
			ID_update: id,
			uppladdning: "",
			namn: namn,
			textarea: beskrivning,
			type: type,
			alkHalt: alkHalt,
			manufaktur: plats,
			//current_img: require("../../uploads/produkter/" + logo).default,
			current_img: process.env.PUBLIC_URL + "/uploads/produkter/" + logo,
			current_img_name: logo
		});
	}

	cancelEdit = (e) => {
		var answer = window.confirm("Är du säker på att du vill avbryta?");

		if (answer) {
			this.setState({
				edit: false,
				uppladdning: "",
				namn: "",
				textarea: "",
				type: "",
				alkHalt: "",
				manufaktur: "",
				allFormsProdukt: [],
				file: Upload_img,
				current_img: "",
				current_img_name: ""
			});


			$('.addProdukt input').val('');
			$('.addProdukt textarea').val('');
			$('#shares').val('');
			$("#choose_man_select").val('choose_manufaktur');

		} else {
			// Do nothing!
			console.log('Ingen ändring gjord!');
		}
	}


	deleteFormProdukt = (form, pris) => {
		var answer = window.confirm("Är du säker på att du vill ta bort denna?");

		if (answer) {
			let newShoppingCart = this.state.allFormsProdukt.filter(item => item.form !== form || item.pris !== pris);

			this.setState({
				allFormsProdukt: [...newShoppingCart]
			});
		} else {
			console.log('Ingen ändring gjord!');
		}

	}


	updateProdukt = (e) => {
		e.preventDefault();

		const formData = new FormData();

		if (this.state.current_img === this.state.file) {
			formData.append('file', this.state.current_img_name);
			formData.append('upload_new_img', false);
		} else {
			formData.append('old_file', this.state.current_img_name);
			formData.append('file', this.state.uppladdning);
			formData.append('upload_new_img', true);
		}

		formData.append('id', this.state.ID_update);
		formData.append('namn', this.state.namn);
		formData.append('textarea', this.state.textarea);
		formData.append('type', this.state.type);
		formData.append('alkHalt', this.state.alkHalt);
		formData.append('manufaktur', this.state.manufaktur);
		formData.append('formsOfProdukt', JSON.stringify(this.state.allFormsProdukt));

		const config = {
			headers: {
				'content-type': 'multipart/form-data'
			}
		};

		console.log(this.state.ID_update);

		if (this.state.file === Upload_img || this.state.namn.length === 0 || this.state.textarea.length === 0 || this.state.type.length === 0
			|| this.state.alkHalt.length === 0 || this.state.manufaktur === "choose_manufaktur" || JSON.stringify(this.state.allFormsProdukt).length === 2) {
			alert("Alla fält är inte ifyllda!");
		} else {
			axios.post("/api/update/produkt", formData, config)
				.then((response) => {

					this.setState({
						uppladdning: "",
						namn: "",
						textarea: "",
						type: "",
						alkHalt: "",
						manufaktur: "",
						allFormsProdukt: [],
						file: Upload_img,
						ID_update: "",
						edit: false
					});

					$('.addProdukt input').val('');
					$('.addProdukt textarea').val('');
					$('#shares').val('');
					$("#choose_man_select").val('choose_manufaktur');


					console.log(response);

					this.fetchProduct();

					console.log("The change was successfully made!");
				}).catch((error) => {
					console.log("Something went wrong!");
				});
		}
	}


	/* ---------------- NOT IN USE -------------
		moveUpPosition = (e, id, position) => {
			e.preventDefault();
	
			//alert("ID: " + id);
	
	
			const formData = new FormData();
			formData.append('id', id);
			formData.append('positionAbove', position + 1);
			formData.append('positionThis', position);
	
			const config = {
				headers: {
					'content-type': 'multipart/form-data'
				}
			};
	
			axios.post("/api/moveup/produkt", formData, config)
				.then((response) => {
					fetch('/api/produkter')
						.then(res => res.json())
						.then(allProdukter => {
							this.setState({ allProdukter });
						});
					console.log("The change was successfully made!");
				}).catch((error) => {
					console.log("Something went wrong!");
				});
		}
	
	
		moveDownPosition = (e, id, position) => {
			e.preventDefault();
	
			//alert("ID: " + id);
	
			const formData = new FormData();
			formData.append('id', id);
			formData.append('positionUnder', position - 1);
			formData.append('positionThis', position);
	
			const config = {
				headers: {
					'content-type': 'multipart/form-data'
				}
			};
	
			axios.post("/api/movedown/produkt", formData, config)
				.then((response) => {
					fetch('/api/produkter')
						.then(res => res.json())
						.then(allProdukter => {
							this.setState({ allProdukter });
						});
					console.log("The change was successfully made!");
				}).catch((error) => {
					console.log("Something went wrong!");
				});
		}
	*/
	changePos = (id) => {

		//alert(pos);


		$('#produkt' + id + ' .posAdjust p').addClass('displayNonePos');
		$('#produkt' + id + ' .posAdjust input').removeClass('displayNonePos');


		$('#produkt' + id + ' .posAdjust .changePosFirstButton').addClass('displayNonePos');
		$('#produkt' + id + ' .posAdjust .changePosButton').removeClass('displayNonePos');
	}

	cancelPos = (id, pos) => {
		$('#produkt' + id + ' .posAdjust input').val("");
		$('#produkt' + id + ' .posAdjust p').removeClass('displayNonePos');
		$('#produkt' + id + ' .posAdjust input').addClass('displayNonePos');


		$('#produkt' + id + ' .posAdjust .changePosFirstButton').removeClass('displayNonePos');
		$('#produkt' + id + ' .posAdjust .changePosButton').addClass('displayNonePos');
	}

	updatePos = (id, pos) => {
		var value = $('#produkt' + id + ' .posAdjust input').val();
		//alert(this.state.allProdukter.length);

		if (!Number.isInteger(+value)) {
			alert("Värdet är inte ett giltigt nr!");
		} else if (value < 1 || value > this.state.allProdukter.length) {
			alert("Värdet måste inom! " + 1 + " - " + this.state.allProdukter.length);
		} else {


			const formData = new FormData();
			formData.append('id', id);
			formData.append('positionNew', value);
			formData.append('positionOld', pos);

			const config = {
				headers: {
					'content-type': 'multipart/form-data'
				}
			};

			axios.post("/api/change/produkt/pos", formData, config)
				.then((response) => {

					this.fetchProduct();
					
					$('#produkt' + id + ' .posAdjust p').removeClass('displayNonePos');
					$('#produkt' + id + ' .posAdjust input').addClass('displayNonePos');
					$('#produkt' + id + ' .posAdjust input').val("");

					$('#produkt' + id + ' .posAdjust .changePosFirstButton').removeClass('displayNonePos');
					$('#produkt' + id + ' .posAdjust .changePosButton').addClass('displayNonePos');
					console.log("The change was successfully made!");
				}).catch((error) => {
					console.log("Something went wrong!");
				});

		}
	}


	setProduktStart = (id) => {

		var answer = window.confirm("Är du säker på att du vill lägga till produkten på startsidan?");

		if (answer) {
			axios.get("/api/update/produktStart", { params: { id: id, opt: "add" } })
				.then((response) => {
					
					this.fetchProduct();

				}).catch((error) => {
					console.log("Something went wrong!");
				});
		} else {
			// Do nothing!
			console.log('Ingen ändring gjord!');
		}
	}

	removeProduktStart = (id) => {
		var answer = window.confirm("Är du säker på att du vill ta bort produkten från startsidan?");

		if (answer) {
			axios.get("/api/update/produktStart", { params: { id: id, opt: "remove" } })
				.then((response) => {
					
					this.fetchProduct();
					
				}).catch((error) => {
					console.log("Something went wrong!");
				});
		} else {
			// Do nothing!
			console.log('Ingen ändring gjord!');
		}
	}


	render() {

		return (

			<section className="produkter" id="produkter">
				<form className="addProdukt" encType="multipart/form-data">
					<div className="aktorPreview">
						<span className="close" onClick={this.clear_img}></span>
						<label>
							<input type="file" name="file" onChange={this.img_upload} />
							<img src={this.state.file} alt="preview" className="imgPreview" />
						</label>
					</div>

					<input type="text" placeholder="Namn" id="produktName" onChange={this.handle_namn} />

					<input placeholder="Typ av produkt" type="text" id="produktType" onChange={this.handle_type}></input>

					<div className="addProduktFormat">
						{
							this.state.allFormsProdukt.map(allFormsProdukt =>
								<p key={allFormsProdukt.form + allFormsProdukt.pris} onClick={() => this.deleteFormProdukt(allFormsProdukt.form, allFormsProdukt.pris)}>
									{"Behållare: " + allFormsProdukt.form + " Pris: " + allFormsProdukt.pris + " kr/st"}
								</p>
							)
						}

						<input type="text" id="formOfProdukt" placeholder="Lägg till behållare"></input>
						<input type="text" id="priceFormOfProdukt" placeholder="Styck pris"></input>
						<button type="button" onClick={this.add_formOfProdukt}>+</button>

					</div>

					<input placeholder="Alkohol halt" type="text" id="produktAlkHalt" onChange={this.handle_alkHalt}></input>
					<select name="manufaktur" id="choose_man_select" onChange={this.handle_manufaktur}>
						<option value="choose_manufaktur">Välj Tillverkare</option>
						{
							this.state.allAktorer.map(allAktorer =>
								<option value={allAktorer.aktor_id} key={allAktorer.aktor_id}>{allAktorer.namn_aktor}</option>
							)
						}
					</select>


					<textarea placeholder="Beskrivning, MAX 400 Tecken!" onChange={this.handle_textarea}></textarea>

					{!this.state.edit ? (
						<button onClick={this.upload_produkt}>Lägg Till</button>
					) : (
						<>
							<button className="produktEditButtom" onClick={this.updateProdukt}>Uppdatera</button>
							<button className="produktCancelButtom" onClick={this.cancelEdit}>Avbryt</button>
						</>
					)
					}

				</form>


				<div className="allProdukter">
					{
						this.state.allProdukter.map(allProdukter =>

							<div className="produkt" id={"produkt" + allProdukter.produkt_id} key={allProdukter.produkt_id}>
								{/*
								<img src={Arrow} className="moveDownButtom" alt="" onClick={(e) => this.moveDownPosition(e, allProdukter.produkt_id, allProdukter.position)}></img>
								<img src={Arrow} className="moveUpButtom" alt="" onClick={(e) => this.moveUpPosition(e, allProdukter.produkt_id, allProdukter.position)}></img>
								*/}
								{allProdukter.startPage === 1 ? (
									<span className="selectForStart" onClick={() => this.removeProduktStart(allProdukter.produkt_id)}>&#9745;</span>
								) : (
									<span className="selectForStart" onClick={() => this.setProduktStart(allProdukter.produkt_id)}>&#9744;</span>
								)
								}
								<div className="posAdjust">
									<p>{allProdukter.position}</p>
									<input type="text" className="displayNonePos"></input>
									<button className="changePosFirstButton" onClick={() => this.changePos(allProdukter.produkt_id)}>Byt pos</button>
									<button className="changePosButton displayNonePos" onClick={() => this.updatePos(allProdukter.produkt_id, allProdukter.position)}>&#10004;</button>
									<button className="changePosButton displayNonePos" onClick={() => this.cancelPos(allProdukter.produkt_id, allProdukter.position)}>&#10006;</button>
								</div>
								<img src={Edit_icon} className="editButtom" alt="" onClick={
									(e) => this.editProdukt(e, allProdukter.produkt_id, allProdukter.logo_src_produkt, allProdukter.namn_produkt, allProdukter.beskrivning_produkt,
										allProdukter.alkHalt, allProdukter.plats, allProdukter.type, allProdukter.formOfProdukt)
								}></img>
								<span className="deleteButtom" onClick={() => this.deleteProdukt(allProdukter.produkt_id, allProdukter.logo_src_produkt, allProdukter.position)}></span>
								<div className="productImgContainer">
									{/*

									<img src={require("../../uploads/produkter/" + allProdukter.logo_src_produkt).default} alt={allProdukter.namn_produkt + " logo"}></img>
			*/}
									<img src={process.env.PUBLIC_URL + "/uploads/produkter/" + allProdukter.logo_src_produkt} alt={allProdukter.namn_produkt + " logo"}></img>

								</div>
								<p>{allProdukter.namn_produkt}</p>
								<div className="beskrivning">
									{allProdukter.beskrivning_produkt}
								</div>
								<p>{"Alkoholhalt: " + allProdukter.alkHalt + "%"}</p>
								<p className="produktAktorNamn">{allProdukter.namn_aktor}</p>
								<p>{allProdukter.type}</p>
								<div className="produktBottom">
									<select name="format" onChange={this.handleProduct} id={"produkt_id_" + allProdukter.produkt_id}>
										<option value="chooseType">Välj behållare</option>
										{
											JSON.parse(allProdukter.formOfProdukt).map(allForm => {
												this.priceOnProduct = allProdukter.produkt_id;
												return (<option key={allForm.form} value={allForm.form}>{allForm.form}</option>)
											})
										}
									</select>

									<p>{this.getPrice(allProdukter.produkt_id)}  kr/st Ex. moms</p>





									{/*
									<button>Lägg i kundvagn</button>
									*/}
								</div>
							</div>
						)
					}

				</div>
			</section>



		);
	}
}
