import React from "react";

import '../../App.css';

export default class Konton extends React.Component {


	render() {
		return (

			<section className="konton" id="konton">
				konton
			</section>



		);
	}
}