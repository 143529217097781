import React from "react";

import '../../App.css';

export default class StartConsole extends React.Component {


	render() {
		return (

			<section className="startConsole" id="startConsole">
				Start console page
			</section>



		);
	}
}