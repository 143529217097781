import React from "react";
import $, { event } from "jquery";
import axios from 'axios';

import '../../App.css';

export default class AboutConsole extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			about: "",
			settings: []
		}
	}

	componentDidMount() {

		fetch('/api/settings')
			.then(res => res.json())
			.then(settings => {
				this.setState({ settings });
			});

	}

	edit = (oldData, type) => {
		this.setState({
			oldData: oldData
		});


		$("." + type + " button").removeClass('displayNoButton');
		$("." + type + " button:first-of-type").addClass('displayNoButton');

		$("." + type + " textarea").prop('disabled', false);
	}

	cancelEdit = (type) => {


		$("." + type + " button").addClass('displayNoButton');
		$("." + type + " button:first-of-type").removeClass('displayNoButton');

		$("." + type + " textarea").val(this.state.oldData);
		$("." + type + " textarea").prop('disabled', true);
	}

	updateEdit = (id, type) => {
		var answer = window.confirm("Är du säker på att du vill uppdatera texten?");

		var data = $("." + type + " textarea").val();

		const formData = new FormData();
		formData.append('id', id);
		formData.append('txt', data);
		formData.append('type', type);

		const config = {
			headers: {
				'content-type': 'multipart/form-data'
			}
		};

		if (answer) {
			axios.post("/api/update/settings", formData, config)
				.then((response) => {
					//console.log(response);
					fetch('/api/settings')
						.then(res => res.json())
						.then(settings => {
							this.setState({
								settings: settings
							});
						});
					$("." + type + " button").addClass('displayNoButton');
					$("." + type + " button:first-of-type").removeClass('displayNoButton');
					$('.aboutConsole textarea').prop('disabled', true);
					console.log("The change was successfully made!");
				}).catch((error) => {
					console.log("Something went wrong!");
				});
		} else {
			console.log("inga ändringar gjorda!");
		}




	}

	render() {
		return (
			<section className="aboutConsole" id="aboutConsole">

				<h1>Om Oss</h1>

				{this.state.settings.map(settings =>
					settings.about !== "null" ? (
						<div key={settings.setting_id} className="aboutEditText">
							<textarea defaultValue={settings.about} disabled></textarea>

							<div className="aboutEditButtonContainer">
								<button onClick={() => this.edit(settings.about, "aboutEditText")}>Redigera</button>

								<button className="editAbout displayNoButton" onClick={() => this.updateEdit(settings.setting_id, "aboutEditText")}>Uppdatera</button>
								<button className="editAbout displayNoButton" onClick={() => this.cancelEdit("aboutEditText")}>Avbryt</button>
							</div>

						</div>
					) : (
						<></>
					)
				)
				}

				<h1>Kontakt uppgifter</h1>
				{this.state.settings.map(settings =>
					settings.about !== "null" ? (
						<div className="contactEditText" key={settings.setting_id}>
							<textarea defaultValue={settings.contact} disabled></textarea>

							<div className="aboutEditButtonContainer">
								<button onClick={() => this.edit(settings.contact, "contactEditText")}>Redigera</button>

								<button className="editAbout displayNoButton" onClick={() => this.updateEdit(settings.setting_id, "contactEditText")}>Uppdatera</button>
								<button className="editAbout displayNoButton" onClick={() => this.cancelEdit("contactEditText")}>Avbryt</button>
							</div>
						</div>
					) : (
						<></>
					)
				)
				}
			</section>



		);
	}
}