import React from "react";
import $ from "jquery";


import Bryggerilagret from "./about/bryggerilagret";
import Kontakt from "./about/kontakt";
import Nyheter from "./about/news";
import Integritetspolicy from "./about/integritetspolicy";

import '../App.css';

export default class About extends React.Component {

	constructor() {
		super();
		this.state = {
			aboutPagesNotShowing: ["kontakt", "integritetspolicy", "newsAbout"],
			allAboutPages: ["bryggerilagret", "kontakt", "integritetspolicy", "newsAbout"]
		}
	}


	componentDidMount() {

		//console.log(this.state.consolePagesNotShowing);

		this.state.aboutPagesNotShowing.map(aboutPagesNotShowing =>
			$("." + aboutPagesNotShowing).hide()
		)

		$(".choose_bryggerilagret").addClass("sidenavSelected");
	}

	updatePage(showPage) {
		const hidenPages = this.state.allAboutPages.filter(i => i !== showPage);

		hidenPages.map(function (hidenPages) {
			return (
				$("." + hidenPages).hide(),
				$(".choose_" + hidenPages).removeClass('sidenavSelected')
				//console.log(hidenPages)
			)
		}
		)

		$("." + showPage).show();
		$(".choose_" + showPage).addClass("sidenavSelected");

		//console.log(hidenPages);

	}

	render() {
		return (

			<section className="about" id="about">
				<div className="sidenavAbout">
					<p className="choose_bryggerilagret" onClick={() => this.updatePage("bryggerilagret")}>Bryggerilagret</p>
					<p className="choose_kontakt" onClick={() => this.updatePage("kontakt")}>Kontakt</p>
					<p className="choose_newsAbout" onClick={() => this.updatePage("newsAbout")}>Nyheter</p>
					{/*
						<p className="choose_integritetspolicy" onClick={() => this.updatePage("integritetspolicy")}>Integritetspolicy</p>
					*/}
				</div>

				<Nyheter></Nyheter>

				<Bryggerilagret></Bryggerilagret>
				<Kontakt></Kontakt>

				{/*
					<Integritetspolicy></Integritetspolicy>
				*/}

			</section>



		);
	}
}