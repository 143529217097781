import React from "react";
import $ from "jquery";
import axios from 'axios';
import Cookies from 'js-cookie';

import '../App.css';

import Aktorer from '../layout/console/aktorer';
import Bestallningar from '../layout/console/bestallningar';
import Konton from '../layout/console/konton';
import Produkter from '../layout/console/produkter';
import StartConsole from '../layout/console/startConsole';


import AboutConsole from '../layout/console/aboutConsole';
import Nyheter from '../layout/console/nyheter';



export default class Console extends React.Component {

    constructor() {
        super();
        this.state = {
            consolePagesNotShowing: ["produkter", "aktorer", "konton", "bestallningar", "aboutConsole", "nyheter"],
            allConsolePages: ["startConsole", "produkter", "aktorer", "konton", "bestallningar", "aboutConsole", "nyheter"],
            loggedIn: Cookies.get("completeAdminName"),
			name: "",
			password: ""
        }

		this.handle_login = this.handle_login.bind(this);
    }

    componentDidMount() {

        //console.log(this.state.consolePagesNotShowing);

        this.state.consolePagesNotShowing.map(consolePagesNotShowing =>
            $("." + consolePagesNotShowing).hide()
        )

        $(".choose_startConsole ").addClass("sidenavSelected");
    }

    updatePage(showPage) {
        const hidenPages = this.state.allConsolePages.filter(i => i !== showPage);

        hidenPages.map(function (hidenPages) {
            return (
                $("." + hidenPages).hide(),
                $(".choose_" + hidenPages).removeClass('sidenavSelected')
                //console.log(hidenPages)
            )
        }
        )

        $("." + showPage).show();
        $(".choose_" + showPage).addClass("sidenavSelected");

        //console.log(hidenPages);

    }

    loginForm = () => {

		const formData = new FormData();
		formData.append('name', this.state.name);
		formData.append('password', this.state.password);

		axios.post("/api/login/admin", formData)
			.then((response) => {
				
				if (JSON.stringify(response.data).length > 2) {
					//console.log(response.data[0].email);
					this.setState({
						completeName: response.data[0].namn
					}, () => {
						Cookies.set('completeAdminName', this.state.completeName, { expires: 7 });
					});
					window.location.reload();
				} else {
					alert("Inloggnings uppgifter stämmer inte")
				}
			}).catch((error) => {
				console.log("något gick fel");
			});


	}

    handle_login(event) {
		//console.log(event.target.name + " " + event.target.value);

		this.setState({
			[event.target.name]: event.target.value
		});

	}

    logut = () => {

		axios.post("/api/logout/admin")
			.then((response) => {

				window.location.reload();

			}).catch((error) => {
				console.log("något gick fel");
			});
	}

    render() {
        return (
            <div className="consolePage">

                {!this.state.loggedIn ? (

                    <div className="loginConsole">
                        <h1>Console Panel</h1>
                        <input type="text" name="name" placeholder="Username" onChange={this.handle_login}></input>
                        <input type="password" name="password" placeholder="Password" onChange={this.handle_login}></input>
                        <button onClick={this.loginForm}>Logga in</button>
                    </div>


                ) : (
                    <div>
                        <div className="sidenavConsole">
                            <p>Meny</p>
                            <a href="#about" className="choose_startConsole" onClick={() => this.updatePage("startConsole")}>Start</a>
                            <a href="#about" className="choose_produkter" onClick={() => this.updatePage("produkter")}>Produkter</a>
                            <a href="#about" className="choose_aktorer" onClick={() => this.updatePage("aktorer")}>Aktörer</a>
                            <a href="#about" className="choose_konton" onClick={() => this.updatePage("konton")}>Konton</a>
                            <a href="#about" className="choose_bestallningar" onClick={() => this.updatePage("bestallningar")}>Beställningar</a>
                            <a href="#about" className="choose_aboutConsole" onClick={() => this.updatePage("aboutConsole")}>Om oss</a>
                            <a href="#about" className="choose_nyheter" onClick={() => this.updatePage("nyheter")}>Nyheter</a>
                        </div >

                        <h1>Consolen</h1>
                        <div className="consoleAdminInfo">
                            <p>{this.state.loggedIn}</p>
                            <p onClick={this.logut}>Logga ut</p>
                        </div>
                        <StartConsole></StartConsole>
                        <Produkter></Produkter>
                        <Aktorer></Aktorer>
                        <Konton></Konton>
                        <Bestallningar></Bestallningar>
                        <AboutConsole></AboutConsole>
                        <Nyheter></Nyheter>
                    </div >
                )
                }
            </div>


        );
    }
}