import React from "react";

import '../../App.css';

export default class Kontakt extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			settings: []
		}
	}

	componentDidMount() {

		fetch('/api/settings')
			.then(res => res.json())
			.then(settings => {
				this.setState({ settings });
			});

	}


	render() {
		return (

			<section className="kontakt" id="kontakt">
				<p className="aboutRubrik">Kontakt</p>

{/*
				<p>info@bryggerilagret.se</p>
				<p>Heikki 0707743937</p>
				<p>Elis 0760491819</p>

*/}

				{this.state.settings.map(settings =>
					settings.contact !== "null" ? (
						<p key={settings.setting_id}>{settings.contact}</p>
					) : (
						<></>
					)
				)
				}
			</section>



		);
	}
}