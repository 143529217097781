import React from "react";
import $ from "jquery";
import axios from 'axios';

import Upload_img from "../../img/img_upload.png";

import '../../App.css';

export default class Nyheter extends React.Component {


	constructor(props) {
		super(props)
		this.state = {
			file: [],
			uppladdning: [],
			rubrik: "",
			url: "",
			completeTxt: "",
			ingress: "",
			news: [],
			count: 1
		}

		this.img_upload = this.img_upload.bind(this);
		this.handle_change = this.handle_change.bind(this);
	}


	img_upload(event) {

		let file = event.target.files[0];
		let reader = new FileReader();

		if (event.target.files.length === 0) {
			return;
		}

		reader.onloadend = (e) => {
			/*
			this.setState({
				file: [...this.state.file, reader.result],
				uppladdning: event.target.files[0]
			}, () => console.log(this.state.file));
			*/

			this.setState({
				file: [...this.state.file,
				{
					id: this.state.file.length + 1,
					namn: reader.result
				}
				],
				uppladdning: [...this.state.uppladdning, {
					id: this.state.uppladdning.length + 1,
					namn: event.target.files[0]
				}
				]
			});

			$(".imgPreview").css({
				"width": "auto"
			});

			$(".close").css({
				"display": "block"
			});

			$(".nyheter_img_pre input").val('');
		}

		reader.readAsDataURL(file);
	}

	clear_img = (id) => {

		/*
		this.setState({
			file: Upload_img
		});
*/
		let newFile = this.state.file.filter(item => item.id !== id);
		let newUppladdning = this.state.uppladdning.filter(image => image.id !== id);


		this.setState({
			file: [...newFile],
			uppladdning: [...newUppladdning]
		});
	}


	submitNews = () => {


		const formData = new FormData();

		this.state.uppladdning.map(images =>
			//console.log("hello" + images)
			formData.append('file', images.namn)
		)

		formData.append('rubrik', this.state.rubrik);
		formData.append('ingress', this.state.ingress);
		formData.append('completeTxt', this.state.completeTxt);
		formData.append('url', this.state.url);



		const config = {
			headers: {
				'content-type': 'multipart/form-data'
			}
		};

		axios.post("/api/upload/news", formData, config)
			.then((response) => {

				this.setState({
					file: [],
					uppladdning: [],
					rubrik: "",
					ingress: "",
					completeTxt: "",
					url: ""
				});
				$('.addNews input').val('');
				$('.addNews textarea').val('');

				//console.log(response);

				fetch('/api/news')
					.then(res => res.json())
					.then(news => {
						this.setState({ news });

						this.state.news.map(news => {
							return (
								//console.log(JSON.parse(news.all_img).length + " " + news.news_id),
								$("#movingSliderNews" + news.news_id).css('width', (JSON.parse(news.all_img).length * 100) + '%'),
								$("#movingSliderNews" + news.news_id + " div").css('width', (100 / JSON.parse(news.all_img).length) + '%')
							)
						}
						)
					});


				console.log("The file is successfully uploaded");
			}).catch((error) => {
				console.log("Something went wrong!");
			});

	}


	handle_change(event) {
		this.setState({
			[event.target.name]: event.target.value
		});
	}


	componentDidMount() {


		fetch('/api/news')
			.then(res => res.json())
			.then(news => {
				this.setState({ news });

				this.state.news.map(news => {
					return (
						//console.log(JSON.parse(news.all_img).length + " " + news.news_id),
						$("#movingSliderNews" + news.news_id).css('width', (JSON.parse(news.all_img).length * 100) + '%'),
						$("#movingSliderNews" + news.news_id + " div").css('width', (100 / JSON.parse(news.all_img).length) + '%')
					)
				}
				)
			});


	}


	nextImg = (id, antal) => {
		//alert(id)

		var currentNr = parseInt($("#imgCounter" + id).text());

		if (currentNr < antal) {
			$("#movingSliderNews" + id).animate({ "left": '-=100%' });
			currentNr = currentNr + 1;
			$("#imgCounter" + id).text(currentNr);
		}
	}

	prevImg = (id) => {
		//alert(id)

		var currentNr = parseInt($("#imgCounter" + id).text());

		if (currentNr > 1) {
			$("#movingSliderNews" + id).animate({ "left": '+=100%' });
			currentNr = currentNr - 1;
			$("#imgCounter" + id).text(currentNr);
		}
	}


	getDate = (id) => {

		var time_to_show = id;
		var t = new Date(time_to_show);
		var formatted = (t.toLocaleString()).slice(0, 10);
		return formatted;
	}

	deleteNews(id) {
		var answer = window.confirm("Är du säker på att du vill ta bort nyheten?");

		if (answer) {

			axios.get("/api/delete/news", { params: { newsID: id } })
				.then((response) => {
					fetch('/api/news')
						.then(res => res.json())
						.then(news => {
							this.setState({ news });

							this.state.news.map(news => {
								return (
									//console.log(JSON.parse(news.all_img).length + " " + news.news_id),
									$("#movingSliderNews" + news.news_id).css('width', (JSON.parse(news.all_img).length * 100) + '%'),
									$("#movingSliderNews" + news.news_id + " div").css('width', (100 / JSON.parse(news.all_img).length) + '%')
								)
							}
							)
						});
				}).catch((error) => {
					console.log("Something went wrong!");
				});

		} else {
			// Do nothing!
			console.log('Ingen ändring gjord!');
		}
	}


	setNewsStart = (id) => {

		var answer = window.confirm("Är du säker på att du vill lägga till produkten på startsidan?");

		if (answer) {
			axios.get("/api/update/newsStart", { params: { id: id, opt: "add" } })
				.then((response) => {
					fetch('/api/news')
						.then(res => res.json())
						.then(news => {
							this.setState({ news });

							this.state.news.map(news => {
								return (
									//console.log(JSON.parse(news.all_img).length + " " + news.news_id),
									$("#movingSliderNews" + news.news_id).css('width', (JSON.parse(news.all_img).length * 100) + '%'),
									$("#movingSliderNews" + news.news_id + " div").css('width', (100 / JSON.parse(news.all_img).length) + '%')
								)
							}
							)
						});
				}).catch((error) => {
					console.log("Something went wrong!");
				});
		} else {
			// Do nothing!
			console.log('Ingen ändring gjord!');
		}
	}

	removeNewsStart = (id) => {
		var answer = window.confirm("Är du säker på att du vill ta bort produkten från startsidan?");

		if (answer) {
			axios.get("/api/update/newsStart", { params: { id: id, opt: "remove" } })
				.then((response) => {
					fetch('/api/news')
						.then(res => res.json())
						.then(news => {
							this.setState({ news });

							this.state.news.map(news => {
								return (
									//console.log(JSON.parse(news.all_img).length + " " + news.news_id),
									$("#movingSliderNews" + news.news_id).css('width', (JSON.parse(news.all_img).length * 100) + '%'),
									$("#movingSliderNews" + news.news_id + " div").css('width', (100 / JSON.parse(news.all_img).length) + '%')
								)
							}
							)
						});
				}).catch((error) => {
					console.log("Something went wrong!");
				});
		} else {
			// Do nothing!
			console.log('Ingen ändring gjord!');
		}
	}





	render() {

		return (

			<section className="nyheter" id="nyheter">


				<div className="addNews">

					<input type="text" placeholder="Rubrik" name="rubrik" onChange={this.handle_change}></input>


					<input type="text" placeholder="Url till klipp" name="url" onChange={this.handle_change}></input>
					<textarea placeholder="Hela Texten" name="completeTxt" onChange={this.handle_change}></textarea>

					<textarea placeholder="Ingress" name="ingress" onChange={this.handle_change}></textarea>

					<div className="nyheter_img_pre_all">

						<h1>Max 10 bilder!</h1>
						<label htmlFor="file-upload" className="custom-file-upload">
							&#10010; Ladda upp bild
						</label>
						{this.state.file.length < 10 ? (
							<input type="file" id="file-upload" name="file" onChange={(e) => this.img_upload(e)} />
						) : (
							<input type="file" id="file-upload" name="file" onChange={(e) => this.img_upload(e)} disabled />
						)}

						{this.state.file.map(allImages =>
							<div className="new_img_preview" key={allImages.id}>
								{this.state.file.length > 0 ? (
									<>
										<span className="close" onClick={() => this.clear_img(allImages.id)}></span>
										<img src={allImages.namn} alt="preview" className="imgPreview" />
									</>
								) : <p>Inga Bilder!</p>}
							</div>
						)}


						<button onClick={this.submitNews}>Lägg TIll</button>

					</div>


				</div>


				{
					this.state.news.map(news =>
						<div className="completeNews" id="completeNews" key={news.news_id}>
							{news.startPage === 1 ? (
								<span className="selectForStart" onClick={() => this.removeNewsStart(news.news_id)}>&#9745;</span>
							) : (
								<span className="selectForStart" onClick={() => this.setNewsStart(news.news_id)}>&#9744;</span>
							)
							}
							<span className="deleteButtom" onClick={() => this.deleteNews(news.news_id)}></span>

							{news.all_img === "[0]" ? (
								<>
									<p className="noimgNews">{news.rubrik}</p>
									<p className="noimgNews">{news.completeTxt}</p>
								</>
							) : (
								<>
									<div className="imageSliderNews">
										{
											JSON.parse(news.all_img).length > 1 ? (
												<div className="prev_img" onClick={() => this.prevImg(news.news_id)}>
													&#10092;
												</div>
											) : (
												<></>
											)
										}


										<div className="movingSliderNews" id={"movingSliderNews" + news.news_id}>
											{
												JSON.parse(news.all_img).map((images, i) => {
													return (
														<div className="imageSliderContainer" id={"imageSliderContainer" + news.news_id} key={images.img}>
															{/*
															<img src={require("../../uploads/newsimg/" + images.img + "").default} alt={i + " logo"}></img>
														*/}
															<img src={process.env.PUBLIC_URL + "/uploads/newsimg/" + images.img} alt={images.img + " logo"}></img>
														</div>

													)
												})
											}
										</div>

										{
											JSON.parse(news.all_img).length > 1 ? (
												<>
													<div className="next_img" onClick={() => this.nextImg(news.news_id, JSON.parse(news.all_img).length)}>
														&#10093;
													</div>
													<div className="imgCounter" id={"imgCounter" + news.news_id}>
														1
													</div>
												</>
											) : (
												<></>
											)
										}
									</div>
									<p>{news.rubrik}</p>
									<p>{news.completeTxt}</p>
								</>
							)
							}

							<p>{this.getDate(news.date)}</p>
						</div>
					)
				}

			</section>



		);
	}
}