import React from "react";
import Cookies from 'js-cookie';
import { Link } from "react-scroll";
import { Link as LinkPage } from 'react-router-dom';

import '../App.css';

export default class OpenMenyNav extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			shoppingCart: [],
			userInfo: [],
			adress: "",
			postnummer: "",
			postort: "",
			name: Cookies.get("completeName")
		};


	}



	closeMenyNav = () => {
		document.getElementById("openMenyNav").style.width = "0";
		document.getElementById("main").style.marginLeft = "0px";
		document.getElementById("headerMain").style.marginLeft = "0px";
	}


	render() {

		var page;

		if (this.props.page === "notMain") {
			page = false;
		} else {
			page = true;
		}

		return (

			<div id="openMenyNav" className="openMenyNav">
				<p className="closebtnProduktNav" onClick={this.closeMenyNav}>×</p>

				{page ? (
					<>
						<Link to="start"
							spy={true}
							smooth={true}
							offset={-120}
							duration={500}
							onSetActive={this.closeMenyNav}
							activeClass="openMenyNavAselected">
							Start
            			</Link>
						<Link to="produkter"
							spy={true}
							smooth={true}
							offset={-120}
							duration={500}
							onSetActive={this.closeMenyNav}
							activeClass="openMenyNavAselected">
							Produkter
            			</Link>
						<Link to="aktorer"
							spy={true}
							smooth={true}
							offset={-120}
							duration={500}
							onSetActive={this.closeMenyNav}
							activeClass="openMenyNavAselected">
							Bryggerierna
           	 			</Link>
						<Link to="newsAbout"
							spy={true}
							smooth={true}
							offset={-120}
							duration={500}
							onSetActive={this.closeMenyNav}
							activeClass="openMenyNavAselected">
							Nyheter
           	 			</Link>
						<Link to="bryggerilagret"
							spy={true}
							smooth={true}
							offset={-120}
							duration={500}
							onSetActive={this.closeMenyNav}
							activeClass="openMenyNavAselected">
							Bryggerilagret
            			</Link>

					</>
				) : (
					<>
						<LinkPage to={
							{
								pathname: 'home',
								state: {
									scrollTo: "start",
									from: "mobile"
								}
							}
						}>
							Hem
						</LinkPage>
						<LinkPage to={
							{
								pathname: 'home',
								state: {
									scrollTo: "produkter",
									from: "mobile"
								}
							}
						}>
							Produkter
						</LinkPage>
						<LinkPage to={
							{
								pathname: 'home',
								state: {
									scrollTo: "aktorer",
									from: "mobile"
								}
							}
						}>
							Bryggerierna
						</LinkPage>
						<LinkPage to={
							{
								pathname: 'home',
								state: {
									scrollTo: "newsAbout",
									from: "mobile"
								}
							}
						}>
							Nyheter
						</LinkPage>
						<LinkPage to={
							{
								pathname: 'home',
								state: {
									scrollTo: "about",
									from: "mobile"
								}
							}
						}>
							Bryggerilagret
						</LinkPage>
					</>
				)
				}
			</div>

		);
	}
}