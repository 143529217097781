import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import axios from 'axios';
import Cookies from 'js-cookie';

import '../App.css';

export default class Sidenav extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			email: "",
			password: "",
			B_password: "",
			firstname: "",
			surname: "",
			orgNr: "",
			bolagsnamn: "",
			reCaptchaResponse: null,
			completeName: ""
		}

		this.handleChangeRegForm = this.handleChangeRegForm.bind(this);

	}

	closeNav = () => {
		document.getElementById("mySidebar").style.width = "0";
		document.getElementById("main").style.marginLeft = "0";
		document.getElementById("headerMain").style.marginLeft = "0px";
	}

	switchForm = (type) => {
		if (type === "regForm") {
			document.getElementById("loginForm").style.marginLeft = "-450px";
		}
		else if (type === "loginForm") {
			document.getElementById("loginForm").style.marginLeft = "0px";
		}

	}

	check_user = async () => {
		const checkForm = new FormData();
		checkForm.append('email', this.state.email);

		return await axios.post('/api/check/user', checkForm)
			.then((response) => {
				if (response.data.length > 0) {
					//alert("användare finns");
					return false;
				} else if (response.data.length === 0) {
					return true;
				}
			}).catch((error) => {
				console.log("Something went wrong!");
			});
	}

	validate_regForm = async () => {

		if (this.state.password !== this.state.B_password || this.state.password.length < 6) {
			return false;
		}

		if (this.state.reCaptchaResponse === null) {
			return false;
		}

		return true;
	}

	register_user = async (e) => {
		e.preventDefault();

		var formIsValid = await this.validate_regForm();
		var checkForUser = await this.check_user();

		if (formIsValid && checkForUser) {
			//console.log("ingen användare hittad: " + this.state.emailError);

			const formData = new FormData();
			formData.append('email', this.state.email);
			formData.append('password', this.state.password);
			formData.append('firstname', this.state.firstname);
			formData.append('surname', this.state.surname);
			formData.append('orgNr', this.state.orgNr);
			formData.append('bolagsnamn', this.state.bolagsnamn);


			axios.post("/api/register/user", formData)
				.then((response) => {
					console.log("användare registrerad");
					console.log(response);


					/*	Fixa så det blir en fin tack sida när man har registrerat klart - JE */
					
					
					
					//window.location.reload();
				}).catch((error) => {
					console.log("något gick fel");
				});

		} else {
			console.log("Något gick fel");
		}
	}

	handleChangeRegForm(event) {
		//console.log(event.target.name + " " + event.target.value);

		this.setState({
			[event.target.name]: event.target.value
		});

	}

	verifyCallback = response => {
		this.setState({
			reCaptchaResponse: response
		});
	};


	loginForm = () => {

		const formData = new FormData();
		formData.append('email', this.state.email);
		formData.append('password', this.state.password);
		console.log(this.state.email);
		axios.post("/api/login/user", formData)
			.then((response) => {
				console.log(response);
				if (JSON.stringify(response.data).length > 2) {
					//console.log(response.data[0].email);
					this.setState({
						completeName: response.data[0].firstname + " " + response.data[0].surname
					}, () => {
						Cookies.set('completeName', this.state.completeName, { expires: 7 });
						//console.log("cookie: " + Cookies.get("completeName"));
					});
					window.location.reload();
				} else {
					alert("Inloggnings uppgifter stämmer inte")
				}
			}).catch((error) => {
				console.log(error);
			});


	}


	render() {
		return (

			<div id="mySidebar" className="sidebar">
				<p className="closebtn" onClick={this.closeNav}>×</p>
				<div className="formContainer">
					<form className="loginForm" id="loginForm">
						<h1>Logga in</h1>
						<label>
							<input placeholder=" " id="loginEmail" type="email" name="email" onChange={this.handleChangeRegForm}></input>
							<span>E-post</span>
						</label>
						<label>
							<input placeholder=" " id="loginPass" type="password" name="password" onChange={this.handleChangeRegForm}></input>
							<span>Lösenord</span>
						</label>
						<button className="logginButton" type="button" onClick={this.loginForm}>Logga in</button>
						<div className="loginFormSelectOther">
							<p onClick={() => this.switchForm("regForm")}>Inget konto? Registrera dig</p>
							<p>Glömt lösenord?</p>
						</div>
					</form>
					<form className="regForm">
						<h1>Registrering</h1>
						<label>
							<input placeholder=" " type="email" name="email" onChange={this.handleChangeRegForm}></input>
							<span>E-post</span>
						</label>
						<label>
							<input placeholder=" " type="password" name="password" onChange={this.handleChangeRegForm}></input>
							<span>Lösenord (minimum 6 tecken)</span>
						</label>
						<label>
							<input placeholder=" " type="password" name="B_password" onChange={this.handleChangeRegForm}></input>
							<span>Bekräfta lösenord</span>
						</label>
						<label>
							<input placeholder=" " type="text" name="firstname" onChange={this.handleChangeRegForm}></input>
							<span>Förnamn</span>
						</label>
						<label>
							<input placeholder=" " type="text" name="surname" onChange={this.handleChangeRegForm}></input>
							<span>Efternamn</span>
						</label>
						<label>
							<input placeholder=" " type="text" name="orgNr" onChange={this.handleChangeRegForm}></input>
							<span>Organisationsnummer</span>
						</label>
						<label>
							<input placeholder=" " type="text" name="bolagsnamn" onChange={this.handleChangeRegForm}></input>
							<span>Företagsnamn</span>
						</label>
						<ReCAPTCHA
							className="g-recaptcha"
							sitekey="6Lf5mWMaAAAAAPI5C8H3xTZkMEIvAgcdme01PjEE"
							theme="dark"
							onChange={this.verifyCallback}
						/>
						<button className="logginButton" onClick={this.register_user}>Registrera dig</button>
						<div className="loginFormSelectOther">
							<p onClick={() => this.switchForm("loginForm")}>Har redan ett konto? Logga in</p>
						</div>
					</form>
				</div>
			</div>


		);
	}
}