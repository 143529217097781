import React from "react";
import { Redirect } from 'react-router';
import axios from 'axios';
import $ from "jquery";

import '../App.css';

import Header from '../layout/header';
import Footer from '../layout/footer';
import OpenMenyNav from '../layout/openMenyNav';

import BryggLogo from "../img/Bryggerilagret_Logga_png.png";



export default class Partner extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            aktor_id: "",
            allProdukter: [],
            allAktor: []
        };


        this.priceOnProduct = "";

        this.handleProduct = this.handleProduct.bind(this);
    }


    componentDidMount() {


        window.scrollTo(0, 0);

        if (document.cookie.indexOf('accepted_terms') < 0) {
            //window.location = '/premium';
            //alert(Cookies.get('accepted_terms'));
            this.setState({ redirect: true });
        }

        this.getPrice = (id) => {


            let getOption = $("#produkt_id_" + id + " option:selected").text();

            const productId = id;
            let productPris = this.state.allProdukter.filter(item => item.produkt_id === productId);
            let getPriceForm = JSON.parse(productPris[0].formOfProdukt).filter(itemPrice => itemPrice.form === getOption);

            if (getOption === "Välj behållare" || getOption === "") {
                return "0";
            } else {
                return getPriceForm[0].pris;
            }
        }

        this.fetchProducts();
        this.fetchAktor();

        //console.log(this.props.location.state.brygg);
    }



    fetchProducts = () => {
        const formData = new FormData();
        formData.append('aktorID', this.props.location.state.brygg);

        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };

        var self = this;

        axios.post("/api/partner/product", formData, config)
            .then(function (response) {
                //console.log(response.data);

                self.setState({ allProdukter: response.data })
            })
            .catch(function (error) {
                console.log(error);
            })
            .then(function () {
                // always executed
            });
    }


    fetchAktor = () => {
        const formData = new FormData();
        formData.append('aktorID', this.props.location.state.brygg);

        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };

        var self = this;

        axios.post("/api/aktorer/page", formData, config)
            .then(function (response) {
                //console.log(response.data);

                self.setState({ allAktor: response.data })
            })
            .catch(function (error) {
                console.log(error);
            })
            .then(function () {
                // always executed
            });
    }



    handleProduct(event) {

        this.setState({
            productType: event.target.value
        });
    }


    render() {

        const { redirect } = this.state;

        if (redirect) {
            return <Redirect to='/home' />;
        }

        if (typeof this.props.location.state === "undefined") {
            window.location.href = '/home';
        }

        return (
            <>
                <div className="partnerPage" id="main">

                    <Header updateCartIcon={this.state.itemsInCart} page="notMain"></Header>

                    {this.state.allAktor.map(aktor =>
                        <div className="partnerInfo" key={aktor.aktor_id}>
                            <div className="partnerLogoContainer">

                                {/*
                                <img src={require("../uploads/aktorer/" + aktor.logo_src_aktor + "").default} alt={aktor.namn_aktor + " logo"} />
                                */}
                                <img src={process.env.PUBLIC_URL + "/uploads/aktorer/" + aktor.logo_src_aktor} alt={aktor.namn_aktor + " logo"}></img>

                            </div>
                            <h1>{aktor.namn_aktor}</h1>
                            <p>{aktor.complete_text}</p>
                        </div>
                    )

                    }



                    <section className="produkter" id="produkter">
                        <h1><span>Produkter</span></h1>
                        {
                            this.state.allProdukter.map(allProdukter =>

                                <div className="produkt" key={allProdukter.produkt_id}>
                                    <div className="productImgContainer">

                                        <img src={process.env.PUBLIC_URL + "/uploads/produkter/" + allProdukter.logo_src_produkt} alt={allProdukter.logo_src + " logo"}></img>
                                        {/*   
                                    <img src={require("../uploads/produkter/" + allProdukter.logo_src_produkt + "").default} alt={allProdukter.namn_produkt + " logo"}></img>
*/}

                                    </div>
                                    <p>{allProdukter.namn_produkt}</p>
                                    <p className="beskrivning">
                                        {allProdukter.beskrivning_produkt}
                                    </p>
                                    <p>{"Alkoholhalt: " + allProdukter.alkHalt + "%"}</p>
                                    <p className="produktAktorNamn">{allProdukter.namn_aktor}</p>
                                    <p>{allProdukter.type}</p>
                                    {!this.state.completeName ? (

                                        <div className="produktBottom" id="produktBottom">
                                            <select name="format" onChange={this.handleProduct} id={"produkt_id_" + allProdukter.produkt_id}>
                                                <option value="chooseType">Välj behållare</option>
                                                {
                                                    JSON.parse(allProdukter.formOfProdukt).map(allForm => {
                                                        this.priceOnProduct = allProdukter.produkt_id;
                                                        return (<option key={allForm.form} value={allForm.form}>{allForm.form}</option>)
                                                    })
                                                }
                                            </select>

                                            <p>{this.getPrice(allProdukter.produkt_id)}  kr/st Ex. moms</p>
                                            {/*

										Gör produkt ramen högre om köp knappen ska läggas till


									<button onClick={() => this.addToCart(allProdukter.logo_src, allProdukter.produkt_id, allProdukter.namn, this.getPrice(allProdukter.produkt_id))}>
										Lägg i kundvagn
									</button>
									*/}

                                        </div>
                                    ) : (
                                        <p className="requiredLogin">Inloggning krävs för att handla</p>
                                    )}
                                </div>
                            )
                        }

                    </section>

                    <Footer></Footer>
                </div >


                <OpenMenyNav page="notMain"></OpenMenyNav>
            </>

        );
    }
}