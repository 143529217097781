import React from "react";

import '../../App.css';

export default class Integritetspolicy extends React.Component {


	render() {
		return (

			<section className="integritetspolicy" id="integritetspolicy">
				<p className="aboutRubrik">Integritetspolicy</p>
			</section>



		);
	}
}