import React from "react";
import Cookies from 'js-cookie';
import { Redirect } from 'react-router';

import '../App.css';

import BryggLogo from "../img/Bryggerilagret_Logga_png.png";



export default class LandingPage extends React.Component {

    state = {
        redirect: false
    }

    componentDidMount() {

        if (document.cookie.indexOf('accepted_terms') > -1) {
            //window.location = '/premium';
            //alert(Cookies.get('accepted_terms'));
            this.setState({ redirect: true });
        }
    }


    handle_redirect = () => {
        Cookies.set('accepted_terms', 'accepted', { expires: 7 });
        //console.log(Cookies.get('cookie_name_test_2'));
        this.setState({ redirect: true });
    }

    handle_decline = () => {
        alert("För att besöka denna sidan måste man ha fyllt minst 20 år.");
    }

    render() {

        const { redirect } = this.state;

        if (redirect) {
            return <Redirect to='/home' />;
        }

        return (
            <div className="landingPage">
                <div className="landingPageLogoContainer">
                    <img src={BryggLogo} alt="Bryggerielagret Logo" className="ghost"></img>

                    <div className="shadowFrame">
                        <svg className="shadow" viewBox="0 0 122.436 39.744">
                            <ellipse cx="61" cy="20" rx="70" ry="10" />
                        </svg>
                    </div>
                </div>

                <div className="landingPageWellcome">

                    <h1>Välkommen!</h1>
                    <p>
                        Hej, får vi be om legitimation?
                        Den här webbplatsen innehåller information om alkohol. För att besöka den eller handla måste du ha fyllt 20 år.
                    </p>
                    <p>
                        Vi använder cookies för att Bryggerilagret ska fungera på ett bra sätt. Cookies används bland annat för att spara dina inställningar.
                        När du godkänner cookies accepterar du att cookies sparas på din dator, mobil eller surfplatta.
                        Genom att bekräfta att du är 20 år eller äldra accepterar du även cookies.
                    </p>

                    <button onClick={this.handle_decline}>Jag är under 20</button>
                    <button onClick={this.handle_redirect}>Jag är 20 eller över</button>
                </div>



            </div >


        );
    }
}