import React from "react";
import $ from "jquery";
import axios from 'axios';

import Upload_img from "../../img/img_upload.png";
import Edit_icon from "../../img/edit_icon.png";

import '../../App.css';

export default class Aktorer extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			file: Upload_img,
			allAktorer: [],
			namn: "",
			url: "",
			textarea: "",
			completeText: "",
			edit: false,
			uppladdning: ""
		}

		this.img_upload = this.img_upload.bind(this);
	}

	componentDidMount() {

		fetch('/api/aktorer')
			.then(res => res.json())
			.then(allAktorer => this.setState({ allAktorer }));
	}

	img_upload(event) {

		let file = event.target.files[0];
		let reader = new FileReader();

		if (event.target.files.length === 0) {
			return;
		}

		reader.onloadend = (e) => {
			this.setState({
				file: [reader.result],
				uppladdning: event.target.files[0]
			});

			$(".imgPreview").css({
				"width": "auto"
			});

			$(".close").css({
				"display": "block"
			});
		}

		reader.readAsDataURL(file);
	}

	clear_img = () => {
		this.setState({
			file: Upload_img
		});

		$(".imgPreview").css({
			"width": "50%"
		});

		$(".close").css({
			"display": "none"
		});
	}

	upload_aktor = (e) => {
		e.preventDefault();

		//console.log(this.state.uppladdning);

		const formData = new FormData();
		formData.append('file', this.state.uppladdning);
		formData.append('namn', this.state.namn);
		formData.append('textarea', this.state.textarea);
		formData.append('completeText', this.state.completeText);
		formData.append('url', this.state.url);

		//alert(formData.get("file") + " " + formData.get("namn"));


		const config = {
			headers: {
				'content-type': 'multipart/form-data'
			}
		};

		if (this.state.file === Upload_img || this.state.namn.length === 0 || this.state.textarea.length === 0 || this.state.completeText.length === 0) {
			alert("Alla fält är inte ifyllda!");
		} else {
			axios.post("/api/upload/aktor", formData, config)
				.then((response) => {
					console.log("The file is successfully uploaded");

					this.setState({
						file: Upload_img,
						uppladdning: "",
						namn: "",
						url: "",
						textarea: "",
						completeText: ""
					});

					$('.addAktor input').val('');
					$('.addAktor textarea').val('');

					fetch('/api/aktorer')
						.then(res => res.json())
						.then(allAktorer => this.setState({ allAktorer }));
				}).catch((error) => {
					console.log("Something went wrong!");
				});
		}
	}

	handle_namn = (event) => {
		this.setState({
			namn: event.target.value
		});
	}

	handle_url = (event) => {
		this.setState({
			url: event.target.value
		});
	}

	handle_textarea = (event) => {
		this.setState({
			textarea: event.target.value
		});
	}

	handle_completeText = (event) => {
		this.setState({
			completeText: event.target.value
		});
	}

	deleteAktor(id, logo) {
		var answer = window.confirm("Är du säker på att du vill ta bort aktören? Alla produkter tillhörande denna aktören kommer också att försvinna!");

		if (answer) {
			axios.get("/api/delete/aktor", { params: { aktorID: id, logo: logo } })
				.then((response) => {
					fetch('/api/aktorer')
						.then(res => res.json())
						.then(allAktorer => this.setState({ allAktorer }));
				}).catch((error) => {
					console.log("Something went wrong!");
				});
		} else {
			// Do nothing!
			console.log('Ingen ändring gjord!');
		}
	}

	editAktor = (e, id, logo, namn, beskrivning, completeText, url) => {
		e.preventDefault();

		$('.addAktorName').val(namn);
		$('.completeAktorText').val(completeText);
		$('.tagTextAktor').val(beskrivning);

		if (url === "null") {
			$('.aktor_url').val("");
		} else {
			$('.aktor_url').val(url);
		}

		this.setState({
			edit: true,
			//file: require("../../uploads/aktorer/" + logo).default,
			file: process.env.PUBLIC_URL + "/uploads/aktorer/" + logo,
			ID_update: id,
			//uppladdning: require("../../uploads/aktorer/" + logo).default,
			uppladdning: process.env.PUBLIC_URL + "/uploads/aktorer/" + logo,
			namn: namn,
			textarea: beskrivning,
			completeText: completeText,
			url: url,
			//current_img: require("../../uploads/aktorer/" + logo).default,
			current_img: process.env.PUBLIC_URL + "/uploads/aktorer/" + logo,
			current_img_name: logo
		});
	}

	cancelEdit = (e) => {
		var answer = window.confirm("Är du säker på att du vill avbryta?");


		if (answer) {
			this.setState({
				edit: false,
				file: Upload_img,
				uppladdning: "",
				namn: "",
				url: "",
				textarea: "",
				completeText: "",
				current_img: "",
				current_img_name: ""
			});

			$('.addAktor input').val('');
			$('.addAktor textarea').val('');
		} else {
			// Do nothing!
			console.log('Ingen ändring gjord!');
		}

	}


	updateAktor = (e) => {
		e.preventDefault();


		const formData = new FormData();

		if (this.state.current_img === this.state.file) {
			formData.append('file', this.state.current_img_name);
			formData.append('upload_new_img', false);
		} else {
			formData.append('old_file', this.state.current_img_name);
			formData.append('file', this.state.uppladdning);
			formData.append('upload_new_img', true);
		}

		formData.append('id', this.state.ID_update);
		formData.append('namn', this.state.namn);
		formData.append('textarea', this.state.textarea);
		formData.append('completeText', this.state.completeText);
		formData.append('url', this.state.url);

		const config = {
			headers: {
				'content-type': 'multipart/form-data'
			}
		};

		if (this.state.file === Upload_img || this.state.namn.length === 0 || this.state.textarea.length === 0 || this.state.completeText.length === 0) {
			alert("Alla fält är inte ifyllda!");
		} else {
			console.log("hej!");

			axios.post("/api/update/aktor", formData, config)
				.then((response) => {

					this.setState({
						uppladdning: "",
						namn: "",
						textarea: "",
						completeText: "",
						url: "",
						allFormsProdukt: [],
						file: Upload_img,
						ID_update: "",
						edit: false
					});

					$('.addAktor input').val('');
					$('.addAktor textarea').val('');

					console.log(response);

					fetch('/api/aktorer')
						.then(res => res.json())
						.then(allAktorer => this.setState({ allAktorer }));

					console.log("The change was successfully made!");
				}).catch((error) => {
					console.log("Something went wrong!");
				});

		}

	}


	render() {
		return (

			<section className="aktorer" id="aktorer">

				<form className="addAktor" encType="multipart/form-data">
					<div className="aktorPreview">
						<span className="close" onClick={this.clear_img}></span>
						<label>
							<input type="file" name="file" onChange={this.img_upload} />
							<img src={this.state.file} alt="preview" className="imgPreview" />
						</label>
					</div>

					<input type="text" placeholder="Namn" className="addAktorName" onChange={this.handle_namn} />
					<textarea onChange={this.handle_completeText} className="completeAktorText" placeholder="Hela Företags Beskrivning!"></textarea>
					<textarea onChange={this.handle_textarea} className="tagTextAktor" placeholder="Tag Beskrivning! MAX 250 Tecken!"></textarea>

					<input placeholder="url till företaget" className="aktor_url" type="url" onChange={this.handle_url}></input>


					{!this.state.edit ? (
						<button onClick={this.upload_aktor}>Lägg Till</button>
					) : (
						<>
							<button className="produktEditButtom" onClick={this.updateAktor}>Uppdatera</button>
							<button className="produktCancelButtom" onClick={this.cancelEdit}>Avbryt</button>
						</>
					)
					}

				</form>


				<div className="allAktorer">
					{
						//../img/uploads/${allAktorer.logo_src}
						this.state.allAktorer.map(allAktorer =>
							<div className="aktorContainer" key={allAktorer.aktor_id}>
								<img src={Edit_icon} className="editButtom" alt="" onClick={
									(e) => this.editAktor(e, allAktorer.aktor_id, allAktorer.logo_src_aktor, allAktorer.namn_aktor, allAktorer.beskrivning_aktor,
										allAktorer.complete_text, allAktorer.aktor_url)
								}></img>
								<span className="deleteButtom" onClick={() => this.deleteAktor(allAktorer.aktor_id, allAktorer.logo_src_aktor)}></span>
							{/*	
								<img src={require("../../uploads/aktorer/" + allAktorer.logo_src_aktor + "").default} alt={allAktorer.namn_aktor + " logo"} />
								*/}
								<img src={process.env.PUBLIC_URL + "/uploads/aktorer/" + allAktorer.logo_src_aktor} alt={allAktorer.namn_aktor + " logo"}></img>
								<p>{allAktorer.namn_aktor}</p>
								<p>{allAktorer.beskrivning_aktor}</p>
								<p>Se mer...</p>
							</div>
						)
					}

				</div>


			</section>



		);
	}
}