import React from "react";
import Cookies from 'js-cookie';
import $, { event } from "jquery";
import axios from 'axios';

import '../App.css';

export default class Produkter extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			allProdukter: [],
			completeName: Cookies.get("completeName"),
			productType: "",
			search: "",
			noAlk: "",
			page: 0,
			amount: 16
		}

		this.priceOnProduct = "";

		this.handleProduct = this.handleProduct.bind(this);
	}

	componentDidMount() {
		/*
				fetch('/api/produkter')
					.then(res => res.json())
					.then(allProdukter => {
						this.setState({ allProdukter });
						//console.log(allProdukter);
					});
		*/

		this.fetchProductData();

		this.getPrice = (id) => {
			let getOption = $("#produkt_id_" + id + " option:selected").text();

			const productId = id;
			let productPris = this.state.allProdukter.filter(item => item.produkt_id === productId);
			let getPriceForm = JSON.parse(productPris[0].formOfProdukt).filter(itemPrice => itemPrice.form === getOption);

			if (getOption === "Välj behållare" || getOption === "") {
				return "0";
			} else {
				return getPriceForm[0].pris;
			}
		}
	}


	fetchProductData = () => {
		axios.get("/api/produkter", { params: { search: this.state.search, noAlk: this.state.noAlk, start: (this.state.page * this.state.amount), amount: this.state.amount } })
			.then((response) => {
				this.setState({ allProdukter: response.data });
				//console.log(response)
			}).catch((error) => {
				console.log("Something went wrong!");
			});
	}


	addToCart = (logoSrc, id, namn, price) => {

		var type = document.getElementById("produkt_id_" + id).value;

		//console.log(e);

		if (type === "chooseType") {
			alert("Välj förpackning av produkten");
		} else {
			this.props.addToCart(logoSrc, id, namn, type, price);
		}
	}


	handleProduct(event) {

		this.setState({
			productType: event.target.value
		});
	}


	dynamicSearch = (event) => {

		this.setState({	page: 0	});

		//console.log(event.target.value)
		if (event.target.name === "noAlk") {
			this.setState({
				[event.target.name]: event.target.checked
			}, () => {
				axios.get("/api/produkter", { params: { search: this.state.search, noAlk: this.state.noAlk, start: (this.state.page * this.state.amount), amount: this.state.amount } })
					.then((response) => {
						this.setState({ allProdukter: response.data });
						//console.log(this.state.allProdukter[0].count + " " + this.state.allProdukter.length);
						//console.log(response)
					}).catch((error) => {
						console.log("Something went wrong!");
					});
			});
		} else {
			this.setState({
				[event.target.name]: event.target.value
			}, () => {
				axios.get("/api/produkter", { params: { search: this.state.search, noAlk: this.state.noAlk, start: (this.state.page * this.state.amount), amount: this.state.amount } })
					.then((response) => {
						this.setState({ allProdukter: response.data });
						//console.log(response)
						//console.log(this.state.allProdukter[0].count + " " + this.state.allProdukter.length);
					}).catch((error) => {
						console.log("Something went wrong!");
					});
			});
		}

	}


	nextPage = () => {
		this.setState({
			page: this.state.page + 1
		}, () => this.fetchProductData());
	}

	prevPage = () => {
		this.setState({
			page: this.state.page - 1
		}, () => this.fetchProductData());
	}


	render() {
		return (

			<section className="produkter" id="produkter">

				<div className="produktFilter">
					<input type="text" name="search" placeholder="Sök efter produkt..." onChange={this.dynamicSearch}></input>
					<input type="checkbox" name="noAlk" onChange={this.dynamicSearch}></input>
					<label htmlFor="noAlk">Alkoholfritt</label>
					{/* Välja bryggeri också -------- Välja behållare --------- välja alkoholsort */}
				</div>



				{
					this.state.allProdukter.map(allProdukter =>

						<div className="produkt" key={allProdukter.produkt_id}>
							<div className="productImgContainer">

								<img src={process.env.PUBLIC_URL + "/uploads/produkter/" + allProdukter.logo_src_produkt} alt={allProdukter.namn_produkt + " logo"}></img>
								{/*
									<img src={require("../uploads/produkter/" + allProdukter.logo_src_produkt + "").default} alt={allProdukter.namn + " logo"}></img>
								*/}

							</div>
							<p>{allProdukter.namn_produkt}</p>
							<p className="beskrivning">
								{allProdukter.beskrivning_produkt}
							</p>
							<p>{"Alkoholhalt: " + allProdukter.alkHalt + "%"}</p>
							<p className="produktAktorNamn">{allProdukter.namn_aktor}</p>
							<p>{allProdukter.type}</p>
							{!this.state.completeName ? (

								<div className="produktBottom" id="produktBottom">
									<select name="format" onChange={this.handleProduct} id={"produkt_id_" + allProdukter.produkt_id}>
										<option value="chooseType">Välj behållare</option>
										{
											JSON.parse(allProdukter.formOfProdukt).map(allForm => {
												this.priceOnProduct = allProdukter.produkt_id;
												return (<option key={allForm.form} value={allForm.form}>{allForm.form}</option>)
											})
										}
									</select>

									<p>{this.getPrice(allProdukter.produkt_id)}  kr/st Ex. moms</p>
									{/*

										Gör produkt ramen högre om köp knappen ska läggas till


									<button onClick={() => this.addToCart(allProdukter.logo_src, allProdukter.produkt_id, allProdukter.namn, this.getPrice(allProdukter.produkt_id))}>
										Lägg i kundvagn
									</button>
									*/}

								</div>
							) : (
								<p className="requiredLogin">Inloggning krävs för att handla</p>
							)}
						</div>
					)
				}


				<div className="paginationProdukt">
					{this.state.allProdukter.length > 0 &&
						this.state.allProdukter[0].count > this.state.allProdukter.length &&
						<>
							<button onClick={() => this.prevPage()} disabled={this.state.page === 0}>Tidigare</button>
							<p>{this.state.page + 1}/{Math.ceil(this.state.allProdukter[0].count / this.state.amount)}</p>

							<button onClick={() => this.nextPage()} disabled={((this.state.page + 1) * this.state.amount) >= this.state.allProdukter[0].count}>Nästa</button>
						</>
					}

				</div>
			</section>


		);
	}
}