import React from "react";
import $, { event } from "jquery";

import '../App.css';

export default class News extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			news: [],
			count: 1
		}

	}



	componentDidMount() {


		fetch('/api/news')
			.then(res => res.json())
			.then(news => {
				this.setState({ news });

				this.state.news.map(news => {
					return (
						//console.log(JSON.parse(news.all_img).length + " " + news.news_id),
						$("#movingSliderNews" + news.news_id).css('width', (JSON.parse(news.all_img).length * 100) + '%'),
						$("#movingSliderNews" + news.news_id + " div").css('width', (100 / JSON.parse(news.all_img).length) + '%')
					)
				}
				)
			});


	}


	nextImg = (id, antal) => {
		//alert(id)

		var currentNr = parseInt($("#imgCounter" + id).text());

		if (currentNr < antal) {
			$("#movingSliderNews" + id).animate({ "left": '-=100%' });
			currentNr = currentNr + 1;
			$("#imgCounter" + id).text(currentNr);
		}
	}

	prevImg = (id) => {
		//alert(id)

		var currentNr = parseInt($("#imgCounter" + id).text());

		if (currentNr > 1) {
			$("#movingSliderNews" + id).animate({ "left": '+=100%' });
			currentNr = currentNr - 1;
			$("#imgCounter" + id).text(currentNr);
		}
	}


	getDate = (id) => {

		var time_to_show = id;
		var t = new Date(time_to_show);
		var formatted = (t.toLocaleString()).slice(0, 10);
		return formatted;
	}



	render() {
		return (


			<section className="news" id="news">


			</section>



		);
	}
}