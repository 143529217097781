import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

import '../App.css';

import $ from "jquery";


export default class Aktorer extends React.Component {

	constructor() {
		super();
		this.state = {
			allAktorer: []
		}

		window.addEventListener('scroll', this.myFunction, true);
	}

	componentDidMount() {

		fetch('/api/aktorer')
			.then(res => res.json())
			.then(allAktorer => this.setState({ allAktorer }));


		$('.aktorer').off('scroll').on('scroll', function () {

			console.log("scrolled!");
		});

	}


	render() {
		return (

			<section className="aktorer" id="aktorer">


				{
					//../img/uploads/${allAktorer.logo_src}
					this.state.allAktorer.map(allAktorer =>
					
						//to={`/users/${user.id}`}
						<Link to={
							{ 
								pathname: 'partner', 
								state: { 
									brygg: allAktorer.aktor_id
								} 
							}
						}  key={allAktorer.aktor_id}>
							<div className="aktorContainer" key={allAktorer.aktor_id}>
								{/*	
								<img src={require("../uploads/aktorer/" + allAktorer.logo_src_aktor + "").default} alt={allAktorer.namn_aktor + " logo"} />
							*/}
								<img src={process.env.PUBLIC_URL + "/uploads/aktorer/" + allAktorer.logo_src_aktor} alt={allAktorer.namn_aktor + " logo"}></img>
								<p>{allAktorer.namn_aktor}</p>
								<p>{allAktorer.beskrivning_aktor}</p>
								<p>Se mer...</p>
							</div>
						</Link>
					)
				}
			</section>


		);
	}
}