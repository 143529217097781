import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

import '../App.css';

export default class Start extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			news: [],
		}

	}



	componentDidMount() {


		fetch('/api/news/firstpage')
			.then(res => res.json())
			.then(news => {
				this.setState({ news }, () => console.log(this.state.news));
			});

	}


	getDate = (id) => {

		var time_to_show = id;
		var t = new Date(time_to_show);
		var formatted = (t.toLocaleString()).slice(0, 10);
		return formatted;
	}


	render() {
		return (


			<section className="start" id="start">


				<h1>Vi tar det lilla bryggeriet till den stora marknaden</h1>

				<div className="startNews">
					<h2><span>Nyheter - läs!</span></h2>

					{this.state.news.length === 0 ? (
						<p className="startNewsNoNews">Inga nyheter för tillfället!</p>
					) : (
						this.state.news.map((news) => (
							news.logo_src_produkt === null ? (
								<Link to={
									{
										pathname: 'news',
										state: {
											id: news.news_id
										}
									}
								} key={news.news_id}>

									<div className="top5news" key={news.news_id}>
										<p>{news.rubrik}</p>

										<p>{news.ingress}</p>

										<p className="topNewSeeMore">Se mer...</p>
										<p>{this.getDate(news.date)}</p>
									</div>
								</Link>

							) : (
								<Link to={
									{
										pathname: 'partner',
										state: {
											id: news.news_id,
											brygg: news.plats
										}
									}
								} key={news.news_id}>

									<div className="top5news" key={news.news_id}>
										<p>{news.rubrik}</p>

										<div className="productImgContainer">
											<img src={process.env.PUBLIC_URL + "/uploads/produkter/" + news.logo_src_produkt} alt={news.logo_src_produkt + " logo"}></img>
											{/*
											<img src={require("../uploads/produkter/" + news.logo_src_produkt + "").default} alt={news.logo_src_produkt + " logo"}></img>
											*/}
										</div>
										<p className="newsWithImg">{news.ingress}</p>

										<p className="topNewSeeMore">Se mer...</p>
										<p>{this.getDate(news.date)}</p>
									</div>
								</Link>
							)
						)
						)

					)
					}


				</div>
			</section>



		);
	}
}