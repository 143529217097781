import React from "react";
import { Link } from "react-scroll";
import Cookies from 'js-cookie';
import axios from 'axios';
import { Link as LinkPage } from 'react-router-dom';

import '../App.css';

import BryggLogo from "../img/Bryggerilagret_Logga_png.png";
import ShoppingCart from "../img/shopping-cart-2.png";
import MenyIcon from "../img/icon-menu-512.png";

export default class Header extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			allAktorer: [],
			name: Cookies.get("completeName"),
			amountIncart: 0
		}

	}

	componentDidUpdate() {


		if (this.state.amountIncart !== this.props.updateCartIcon) {
			this.setState({
				amountIncart: this.props.updateCartIcon
			});
		}

	}

	openNav = () => {
		document.getElementById("produktNav").style.width = "0";
		document.getElementById("mySidebar").style.width = "450px";
		document.getElementById("main").style.marginLeft = "-450px";
		document.getElementById("headerMain").style.marginLeft = "-450px";
	}

	openProduktNav = () => {
		document.getElementById("mySidebar").style.width = "0";
		document.getElementById("produktNav").style.width = "650px";
		document.getElementById("main").style.marginLeft = "-650px";
		document.getElementById("headerMain").style.marginLeft = "-650px";
	}

	openMenyNav = () => {

		document.getElementById("openMenyNav").style.width = "250px";
		document.getElementById("headerMain").style.marginLeft = "-250px";
	}

	logut = () => {

		axios.post("/api/logout/user")
			.then((response) => {

				window.location.reload();

			}).catch((error) => {
				console.log("något gick fel");
			});
	}

	render() {

		var page;

		if (this.props.page === "notMain") {
			page = false;
		} else {
			page = true;
		}

		return (

			<header id="headerMain">

				<div className="upperheader">
					<div className="headerAction">
						{/*
						{this.state.name ? (
							<div className="upperHeaderLoginName">
								<p>{Cookies.get("completeName")}</p>
								<p onClick={this.logut}>Logga ut</p>
							</div>
						) : (
								<p onClick={this.openNav}>Konto</p>
							)}
						<div className="shoppingCartAmount">
							<img src={ShoppingCart} alt="kundvagn" className="shopping_img" onClick={this.openProduktNav}></img>
							
							{(() => {
								if (this.state.amountIncart > 9) {
									return (
										<p>9+</p>
									)
								} else if (this.state.amountIncart > 0) {
									return (
										<p>{this.state.amountIncart}</p>
									)
								} else {
									return (
										<p className="amountDisplayNone"></p>
									)
								}
							})()}
						</div>
						{/*
						<input type="text" name="search" />
						*/}
					</div>
				</div>

				<img src={MenyIcon} alt="meny icon" className="menyIcon" onClick={this.openMenyNav}></img>


				<nav className="navigationBar">
					{page ? (
						<>
							<Link to="start"
								spy={true}
								smooth={true}
								offset={-160}
								duration={500}
								activeClass="borderBottomSelected"
								className="select_start">
								Hem
            				</Link>
							<Link to="produkter"
								spy={true}
								smooth={true}
								offset={-160}
								duration={500}
								activeClass="borderBottomSelected"
								className="select_produkter">
								Produkter
            				</Link>


							<img src={BryggLogo} alt="Företags Loga" className="logo" />


							<Link to="about"
								spy={true}
								smooth={true}
								offset={-160}
								duration={500}
								activeClass="borderBottomSelected"
								className="select_about">
								Bryggerilagret
            				</Link>
							<Link to="aktorer"
								spy={true}
								smooth={true}
								offset={-160}
								duration={500}
								activeClass="borderBottomSelected"
								className="select_aktorer">
								Bryggerierna
           	 				</Link>
						</>
					) : (
						<>
							<LinkPage to={
								{
									pathname: 'home',
									state: {
										scrollTo: "start"
									}
								}
							}>
								Hem
							</LinkPage>
							<LinkPage to={
								{
									pathname: 'home',
									state: {
										scrollTo: "produkter"
									}
								}
							}>
								Produkter
							</LinkPage>


							<img src={BryggLogo} alt="Företags Loga" className="logo" />


							<LinkPage to={
								{
									pathname: 'home',
									state: {
										scrollTo: "about"
									}
								}
							}>
								Bryggerilagret
							</LinkPage>
							<LinkPage to={
								{
									pathname: 'home',
									state: {
										scrollTo: "aktorer"
									}
								}
							}>
								Bryggerierna
							</LinkPage>
						</>
					)
					}
				</nav>



			</header>



		);
	}
}