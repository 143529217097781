import './App.css';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Home from './routes/home';
import Console from './routes/console';
import LandingPage from './routes/landingPage';
import Partner from './routes/partner';
import News from './routes/news';

function App() {
  return (

    <Router>
      <Switch>
        <Route path="/" exact component={LandingPage} />
        <Route path="/home" exact component={Home} />
        <Route path="/console" exact component={Console} />
        <Route path="/partner" exact component={Partner} />
        <Route path="/news" exact component={News} />
        <Route component={Home} />
      </Switch>

      
    </Router>


  );
}

export default App;
