import React from "react";
import { Redirect } from 'react-router';
import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';
import $ from "jquery";


import Header from '../layout/header';
import Sidenav from '../layout/sidenav';
import ProduktNav from '../layout/produktNav';
import OpenMenyNav from '../layout/openMenyNav';
import Start from '../layout/start';
import Produkter from '../layout/produkter';
import Aktorer from '../layout/aktorer';
import About from '../layout/about';
import News from '../layout/news';
import Footer from '../layout/footer';

import '../App.css';

export default class Home extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isClicked: false,
            redirect: false,
            shoppingCart: [],
            itemsInCart: 0
        };
        //console.log(this.state)
    }



    scrollTo() {


        if (typeof this.props.location.state !== 'undefined') {
            //console.log(this.props.location.state.scrollTo)
            var id = this.props.location.state.scrollTo;

            if (this.props.location.state.from === "mobile") {
                var top = 116;
            } else {
                var top = 156;
            }


            var logo = document.getElementById(id);
            var logoTextRectangle = logo.getBoundingClientRect();

            //console.log("logo's left pos.:", logoTextRectangle.left);
            //console.log("logo's right pos.:", logoTextRectangle.top);


/* ---------------------------  Det funkar med scroll om section inte har height auto, fixa det på något sätt  ------------------------- */


            $('html, body').animate({
                scrollTop: ($('#' + id).offset().top - 156)
            }, 500);


        }
    }


    componentDidMount() {

        this.scrollTo();

        if (document.cookie.indexOf('accepted_terms') < 0) {
            //window.location = '/premium';
            //alert(Cookies.get('accepted_terms'));
            this.setState({ redirect: true });
        }
    }

    clearCartNr = () => {
        this.setState({
            itemsInCart: 0
        });
    }

    deleteFromCart = (id, type) => {
        //alert(id + " " + type);
        var itemsInCart = 0;

        let newShoppingCart = this.state.shoppingCart.filter(item => item.id !== id || item.type !== type);

        newShoppingCart.map((a) => {
            return itemsInCart = itemsInCart + a.antal;
        });

        this.setState({ itemsInCart });

        this.setState({
            shoppingCart: [...newShoppingCart]
        });


    }

    addToCart = (logoSrc, id, namn, productType, price) => {
        //console.log('I have been clicked ' + id);
        /*
        this.setState({
            isClicked: id
        });
*/
        var copy = false;

        for (var i = 0; i < this.state.shoppingCart.length; i++) {
            if (this.state.shoppingCart[i].id === id && this.state.shoppingCart[i].type === productType) {
                //console.log(id);
                copy = true
                var index = i;
            }
        }

        if (copy) {
            let shoppingCart = [...this.state.shoppingCart];
            let product = { ...shoppingCart[index] };
            product.antal++;
            shoppingCart[index] = product;
            this.setState({ shoppingCart });


            const itemsInCart = this.state.itemsInCart + 1;
            this.setState({ itemsInCart });

        } else {
            this.state.shoppingCart.push({
                id: id,
                namn: namn,
                type: productType,
                antal: 1,
                logo: logoSrc,
                price: price
            });

            const itemsInCart = this.state.itemsInCart + 1;
            this.setState({ itemsInCart });
        }

    }

    render() {

        const { redirect } = this.state;

        if (redirect) {
            return <Redirect to='/' />;
        }

        return (
            <div>
                <div className="mainPage" id="main">
                    <Header updateCartIcon={this.state.itemsInCart}></Header>
                    <Start></Start>
                    <Produkter addToCart={this.addToCart}></Produkter>
                    <Aktorer></Aktorer>
                    <About></About>
                    <Footer></Footer>

                </div>

                {/*
                <Sidenav></Sidenav>
                <ProduktNav shoppingCart={this.state.shoppingCart} updateCartIcon={this.clearCartNr} deleteFromCart={this.deleteFromCart}></ProduktNav>
*/}
                <OpenMenyNav></OpenMenyNav>
            </div>
        );
    }
}